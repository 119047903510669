import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router);

const routes = [
    {
        path: '/',
        component: ()=> import('../view/index.vue')
    }
]

export default new Router({
    routes
})